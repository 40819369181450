@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@300;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@300;600&display=swap");
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none;
  scrollbar-width: none;
  background-color: #f5fdff;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* ms,firefox */
* {
  /* -ms-overflow-style: none;
  scrollbar-width: 10px;
  scrollbar-color: #0d0d86 rgba(0, 0, 0, 0);
  scrollbar-track-color: transparent; */
}

*::-webkit-scrollbar {
  /* opacity: 1;
  width: 10px; */
  display: none;
}

/* *::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #0d0d86;
}

*::-webkit-scrollbar-track {
  background-color: transparent;
} */
